@import url("https://fonts.googleapis.com/css2?family=Phudu:wght@800&display=swap");

@media (max-width: 375px) {
  .gap-24{gap: 0px !important;}
  /* .gap-8{gap: 0px !important;} */
  .hide2{display: none !important;}
  .button2a{padding: 6px 10px 6px 10px !important;}
  .button2{padding: 6px 10px 6px 10px !important;}
  .widthA{width: 100px !important;}
  .widthB{width: 100px !important;}

  .parent-div-mob {
    width: 100%;          /* Set the width of the parent div */
    max-width: 1000px;     /* Optional: limit the max-width if desired */
    justify-self: center;
    height: auto;         /* Adjust height based on your layout needs */
    overflow: hidden;     /* Hide any overflow if needed */
  }
  
  .parent-div-mob img {
    width: 100%;
    height: auto;         /* Maintain the aspect ratio */
    object-fit: cover;    /* Crop to fill if needed, use 'contain' to fit inside without cropping */
    display: block;       /* Remove any spacing under the image */
  }

  .w-1056{width: 100% !important;}
  .w-1110{width: 100% !important;}
  .recBox1{width: 100% !important;}
  .recBox{width: 100% !important;}

  .newBox11{width: 100% !important;}
  .newBox1{width: 100% !important;}
  .sec1template{width: 100% !important;}


  .mt-mob-80{margin-top: 80px !important;}
  .mb-mob-80{margin-bottom: 80px;}

  .text2, .text5{
    font-size: 28px !important;
    font-weight: 800 !important;
    line-height: 30px !important;
    letter-spacing: -0.3700000047683716px !important;
  }

  .parent-div-mob {
    width: 100%;          /* Set the width of the parent div */
    max-width: 1055px;     /* Optional: limit the max-width if desired */
    justify-self: center;
    height: auto;         /* Adjust height based on your layout needs */
    overflow: hidden;     /* Hide any overflow if needed */
  }
  
  .parent-div-mob img {
    width: 100%;
    height: auto;         /* Maintain the aspect ratio */
    object-fit: cover;    /* Crop to fill if needed, use 'contain' to fit inside without cropping */
    display: block;       /* Remove any spacing under the image */
  }

  .boxp{
    width: 100% !important;
  }

  .wrap{flex-flow: wrap !important;}

  .m-w-100{width: 100% !important;}
  .mobOne{
    flex-direction: column;
  }

  .mob-align-center{align-items: center !important;}

  .cssOne{
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mob-width{
    width: 50% !important;
    margin-bottom: 40px;
  }

  .sec1{
    width: 100% !important;
  }
  .mob-mb-130{
    margin-bottom: 60px !important;
  }

  .divtext1{width: 100% !important;}
  
  .newtextbox{
    margin-top: 150px !important;
  }
  .w-110{
    width: 100% !important;
  }
  .boxlast{
    margin-left: 0px !important;
  }
  .lastbox{
    margin-right: 0px !important;
  }
  .mob-img-center{
    width: 100% !important;
    text-align: center !important;
  }
  .wp{width: 100% !important;}
  .wpp{width: 100% !important;}
  .wwp{background: #232323; padding: 14px 21px !important}
  .mr-0{padding-right: 0px !important;}
}
/* @media (max-width: 768px) { */
@media (min-width: 376px) and (max-width: 768px) {
  .mr-0{padding-right: 0px !important;}
  .wwp{background: #232323; padding: 14px 21px !important}
  .wp{width: 100% !important;}
  .wpp{width: 100% !important;}
  .lastbox{
    margin-right: 0px !important;
  }
  .boxlast{
    margin-left: 0px !important;
  }
  
  .w-110{
    width: 100% !important;
  }
  .newtextbox{
    margin-top: 150px !important;
  }
  .divtext1{width: 100% !important;}
  .m-w-100{width: 100% !important;}
  
  .parent-div-mob {
    width: 100%;          /* Set the width of the parent div */
    max-width: 1000px;     /* Optional: limit the max-width if desired */
    justify-self: center;
    height: auto;         /* Adjust height based on your layout needs */
    overflow: hidden;     /* Hide any overflow if needed */
  }
  
  .parent-div-mob img {
    width: 100%;
    height: auto;         /* Maintain the aspect ratio */
    object-fit: cover;    /* Crop to fill if needed, use 'contain' to fit inside without cropping */
    display: block;       /* Remove any spacing under the image */
  }

  .w-1056{width: 100% !important;}
  .w-1110{width: 100% !important;}
  .recBox1{width: 100% !important;}
  .recBox{width: 100% !important;}

  .newBox11{width: 100% !important;}
  .newBox1{width: 100% !important;}
  .sec1template{width: 100% !important;}


  .mt-mob-80{margin-top: 80px !important;}
  .mb-mob-80{margin-bottom: 80px;}

  .text2, .text5{
    font-size: 28px !important;
    font-weight: 800 !important;
    line-height: 30px !important;
    letter-spacing: -0.3700000047683716px !important;
  }

  .parent-div-mob {
    width: 100%;          /* Set the width of the parent div */
    max-width: 1055px;     /* Optional: limit the max-width if desired */
    justify-self: center;
    height: auto;         /* Adjust height based on your layout needs */
    overflow: hidden;     /* Hide any overflow if needed */
  }
  
  .parent-div-mob img {
    width: 100%;
    height: auto;         /* Maintain the aspect ratio */
    object-fit: cover;    /* Crop to fill if needed, use 'contain' to fit inside without cropping */
    display: block;       /* Remove any spacing under the image */
  }

  .boxp{
    width: 100% !important;
  }

  .wrap{flex-flow: wrap !important;}

  .mobOne{
    flex-direction: column;
  }

  .mob-align-center{align-items: center !important;}
  .mob-img-center{
    width: 100% !important;
    text-align: center !important;
  }

  .cssOne{
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .widthA{width: 136.817px !important;}
  .widthB{width: 97.087px !important;}
  .mob-width{
    width: 50% !important;
    margin-bottom: 40px;
  }

  .sec1{
    width: 100% !important;
  }
  .mob-mb-130{
    margin-bottom: 60px !important;
  }
  
}
.mob-mt-50{
  margin-top: 170px;
}
.boxp{
  margin-top: 116px;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 32px;
  border: 1px solid rgba(214, 211, 209, 0.50);
  background: #FFF;
  box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.15);
  width: 80%;
}
.widthA{width: 196.817px;}
.widthB{width: 157.087px;}
.boxpp{
  position: absolute;
  right: 0;
  top: -85px;

}
.mob-mb-130{margin-bottom: 130px;}
.text-align-right{text-align: right;}
.gap-4px{gap: 4px;}
.align-items-center{align-items: center;}
.boxO{
  width: 700px;
  display: inline-flex;
  padding: 12px 15.71px 12px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  border-radius: 10px;
  border: 1px solid rgba(192, 207, 255, 0.22);
}
.boxHead{
  color: #28253B;
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 141.176% */
  letter-spacing: -0.6px;
}
.boxText{
  color: #808080;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.show{display: none;}

.cssOne{
  margin-left: 200px;
  /* margin-top: 200px; */
  margin-bottom: 18px;
}

.w-100{width: 100%;}

.text1{
  /* color: #78726D;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.05px;
  font-family: 'Inter', sans-serif;
  padding: 6px 12px 6px 12px; */
  color: #232323;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.663px; /* 197.895% */
  letter-spacing: -0.158px;
}
.text1b{
  color: #78726D;
  /* font-size: 14px; */
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.05px;
  font-family: 'Inter', sans-serif;
  padding: 6px 12px 6px 12px;
}
.mb-144{
  margin-bottom: 144px;
}
.bar{
  border-bottom: 1px solid rgba(214, 211, 209, 0.50);
}
.mt-60{margin-top: 60px;}
.mb-24{margin-bottom: 24px;}
.mb-85{margin-bottom: 85px;}
.footertext{
  color: #333;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: 0.101px;
}
.headingtext2{
  color: #808080;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.012px;
}
.headingtext1{
  color: #28253B;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 143.75% */
  letter-spacing: -0.012px;
}
.text2{
  /* color: #272735;
  font-size: 70px;
  font-weight: 800;
  line-height: 70.3px;
  letter-spacing: -0.37px;
  font-family: 'Phudu', sans-serif;
  text-align: center; */
  color: #232323;
  text-align: center;
  font-family: Montserrat;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 77px; /* 137.5% */
  letter-spacing: -2.76px;
}
.text-align-center{text-align: center;}
.text3{
  /* text-align: center;
  color: #78716C;
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.14px;
  font-family: 'Inter', sans-serif; */
  color: #9B9B9B;
  text-align: center;
  font-family: Poppins;
  font-size: 19.531px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 143.36% */
  letter-spacing: -0.6px;
}
.mt-60{
margin-top: 60px;
} .mb-35{
  margin-bottom: 35px;
}
.text4{
  margin-top: 25px;
  margin-bottom: -50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14000000059604645px;
  text-align: center;
  color: rgba(120, 113, 108, 1);
  font-family: 'Inter', sans-serif;
}
.mt-10{margin-top: 10px;}
.mt-180{margin-top: 180px;} .mb-80{margin-bottom: 80px;}
.ml-200{margin-left: 200px;}
.text5{
  /* color: #28253B;
  font-family: 'Phudu', sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 52.8px;
  letter-spacing: -0.24px; */
  color: #232323;
  text-align: center;
  font-family: Poppins;
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 112.281% */
  letter-spacing: -1.92px;
}
.mt-50{margin-top: 50px;}
.mb-25{margin-bottom: 25px;}
.mt-200{margin-top: 200px;} .mb-18{margin-bottom: 18px;}
.justify-self-center{
  margin: auto;
  justify-self: center;
  align-self: center;
}
.text8{
  color: #272735;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.05px;
}
.text7{
  color: #78726D;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.05px;
}
.text9{
  color: #28253B;
  font-family: Phudu;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 52.8px; /* 125.714% */
  letter-spacing: -0.24px;
}

.text6{
  /* color: #808080;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.14px; */
  color: #9B9B9B;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.6px;
}
.button1{
  background-color: #52ABEB;
  color: white;
  padding: 12px 28px 12px 28px;
  border-radius: 32px;
  width: fit-content;
  font-size: 18px;
}
.padding{
  padding: 10px 12px 10px 12px;
}
.button2{
  padding: 12px 20px 12px 20px;
  border: 1px solid rgba(192, 207, 255, 0.22);
  backdrop-filter: blur(20px);
  color: rgba(83, 84, 97, 1);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.3199999928474426px;
  border-radius: 40px;
}
.cp{
  cursor: pointer;
}
.three{
  border-radius: 9999px;
  border: 1px solid rgba(214, 211, 209, 0.50);
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  padding: 5px 6px;
  gap: 10px;
  flex-shrink: 0;
  width: fit-content;
}
.flex{display: flex;}
.mt-30{margin-top: 30px;}
.mt-35{margin-top: 35px;}
.mb-100{margin-bottom: 100px;}
.mb-25{margin-bottom: 25px;}
.sec1img{padding-left: 30px;}
.sec1text222{
  color: #808080;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Inter', sans-serif;
  /* font-size: 14px; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* margin-bottom: 15px; */
  margin-bottom: 36px;
  line-height: 20px; /* 142.857% */
}
.sec1text22{
  color: #808080;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Inter', sans-serif;
  /* font-size: 14px; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* margin-bottom: 22px; */
  margin-bottom: 36px;
  line-height: 20px; /* 142.857% */
}
.sec1text2{
  /* color: #808080;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; */
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 36px;
  color: #626161;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.6px;
}
.sec1text1{
  padding-left: 30px;
  margin-top: 60px;
  margin-bottom: 10px;
  color: #232323;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 114.286% */
  letter-spacing: -0.8px;
  /* color: #28253B;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.6px; */
}
.w-fit{width: fit-content;}
.sec1{
  border-radius: 12px;
  background: #F6F5F4;
  width: 335.51px;
  padding-top: 27px;
  height: fit-content;
}
.sec1template{
  border-radius: 12px;
  background: #F6F5F4;
  width: 515px;
  padding-top: 27px;
  height: fit-content;
}
.ml--35{margin-left: -35px;}
.section1{
  /* margin-top: 130px; */
  margin-top: 74px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 24px;
  border-radius: 12px;
  border: 2.062px solid #F6F5F4;
  background: #F6F5F4;
}
.mt-150{margin-top: 150px;}
.mt-170{margin-top: 170px;}
.mt-112{margin-top: 112px;}
.mb-130{margin-bottom: 130px;}
.gap-30{gap: 30px;}
.gap-47{gap: 47px;}
.gap-24{gap: 24px;}
.gap-6{gap: 6px;}
.gap-16{gap: 16px;}
.gap-8{gap: 8px;}
.gap-18{gap: 18px;}
.column{flex-direction: column;display: flex;}
.p-6{padding: 6px;}
.footerlogotext{
  /* color: #808080;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.101px; */
  color: #888787;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 146.429% */
}
.justify-content-space-between{justify-content: space-between;}
.footerdesc{
  color: #888787;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px; /* 120% */
}
.parent-div {
  width: 100%;          /* Set the width of the parent div */
  max-width: 1055px;     /* Optional: limit the max-width if desired */
  justify-self: center;
  height: auto;         /* Adjust height based on your layout needs */
  overflow: hidden;     /* Hide any overflow if needed */
}
.mb-26{margin-bottom: 26px;}
.mt-26{margin-top: 26px;}
.mt-auto{margin-top: auto;}
.z-1{z-index: 1;}
.pl-30{padding-left: 30px;}
.parent-div img {
  /* width: 100%; */
  height: auto;         /* Maintain the aspect ratio */
  object-fit: cover;    /* Crop to fill if needed, use 'contain' to fit inside without cropping */
  display: block;       /* Remove any spacing under the image */
}
.align-self-center{align-self: center;}
.container1{
  display: flex;
  flex-direction: column;
}
.shadow{filter: drop-shadow(0px 3.976px 79.511px rgba(0, 0, 0, 0.15));border-radius: 8px;}
.mt-160{margin-top: 160px;}
.mt-90{margin-top: 90px;}
.mr-100{margin-right: 100px;}
.mt-130{margin-top: 130px;}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid rgba(214, 211, 209, 0.30);
  /* background: #FFFFFF; */
  background: rgba(255, 255, 255, 0.60);
  backdrop-filter: blur(17px);
  z-index: 1000; 
}
.footerheading{
  color: #28253B;
  font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 144.444% */
letter-spacing: -0.252px;
}
.mt-185{margin-top: 185px;}
.mt-18{margin-top: 18px;}
.mt-14{margin-top: 14px;}
.mb-10{margin-bottom: 10px;}
.button2a{
  background: rgba(223, 230, 253, 1);
  padding: 12px 20px 12px 20px;
  border: 1px solid rgba(192, 207, 255, 0.22);
  backdrop-filter: blur(20px);
  color: rgba(39, 39, 53, 1);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.3199999928474426px;
  border-radius: 40px;
}












.headC{
  color: #272735;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  margin-bottom: 30px;
  margin-top: 157px;
}
.headB{
  color: #272735;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 105.263% */
}
.headA{
  color: #78726D;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; /* 140% */
}
.gap-15{gap: 15px;}













.parent{
  position: relative;
  /* margin-top: 280px; */
  margin-top: 180px;
}
.child{
  position: absolute;
  top: -175px;
}
.pr-30{padding-right: 30px;}
.newBox11{
  border-radius: 12px;
  background: #E05C99;
  display: flex;
  padding: 24px 24px 0px 24px;
  width: 1050px;
  align-self: center;
  gap: 124px;
  margin-top: 80px;
}
.newBox1{
  border-radius: 12px;
  background: #627AFE;
  display: flex;
  padding: 24px 24px 0px 24px;
  width: 1050px;
  align-self: center;
  gap: 124px;
  margin-top: 80px;
}
.newBox1Text{
  color: #FFF;
  font-family: 'Inter', sans-serif;
  /* font-size: 14px; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.592px; /* 161.368% */
  padding: 0px 100px 0px 0px !important;
}
.newBox1Head{
  margin-top: 20px;
  margin-bottom: 20px;
  color: #28253B;
  font-family: 'Phudu', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.11px; /* 84.718% */
  letter-spacing: -0.678px;
}











.recBox1{
  border-radius: 8px;
border: 1px solid rgba(214, 211, 209, 0.50);
background: #FFF;
width: 524px;
padding: 24px 66px 45px 49px;

/* Drop/4images */
box-shadow: 0px 6.428px 25.711px 0px rgba(0, 0, 0, 0.05);
}
.recText1{
  color: #28253B;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.88px; /* 168% */ 
}
.recBox{
  border-radius: 8px;
  border: 1px solid rgba(214, 211, 209, 0.50);
  background: #F6F5F4;
  padding: 24px 66px 45px 49px;
  width: 524px;

  /* Drop/4images */
  box-shadow: 0px 6.428px 25.711px 0px rgba(0, 0, 0, 0.05);
}
.recHead{
  margin-bottom: 28px;
  color: #28253B;
  font-family: Phudu;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px; /* 106.25% */
  letter-spacing: -0.24px;
}
.recTxt1{
  display: flex;
  gap: 8px;
  color: #78716C;
  font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 26.88px; /* 192% */
align-items: center;
}
.recTxt{
  margin-bottom: 45px;
  color: #78716C;
  font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26.88px; /* 168% */
}
.recbtn1{
  border-radius: 32px;
background: #52ABEB;
display: flex;
width: 144.22px;
height: 40px;
padding: 12px 19.8px 12px 20px;
justify-content: center;
cursor: pointer;
align-items: center;

color: #FFF;
text-align: center;
font-family: 'Inter', sans-serif;
font-size: 14.5px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 110.345% */
letter-spacing: 0.32px;

margin-top: 85px;
margin-bottom: 4px;
}
.recbtn{
  border-radius: 32px;
  background: #28253B;
  display: flex;
  width: 144.22px;
  height: 40px;
  padding: 12px 19.8px 12px 20px;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  color: #FFF;
text-align: center;
font-family: 'Inter', sans-serif;
font-size: 14.5px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 110.345% */
letter-spacing: 0.32px;

margin-top: 150px;
margin-bottom: 24px;
}
.w-1056{
  width: 1056px;
}
.w-1110{
  /* width: 1110px; */
  width: 972px;
  margin-bottom: -35px;
}

.mt-41{margin-top: 41px;}
.pr-15{padding-right: 15px;}
.pl-15{padding-left: 15px;}










/* anout us */
.divbox{
  border-radius: 6px;
  background: #F0EFEE;
  padding: 8px;
  display: flex;
  /* gap: 90px; */
  justify-self: center;
  width: fit-content;
  margin-top: 62px;
  margin-bottom: 47px;
}
.pr-130{padding-right: 130px;}
.divpara{
  color: #A8A29E;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.05px;
}
.divdesc{
  color: #78716C;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.05px;
}
.mb-18{margin-bottom: 18px;}
.divhead{
  color: #272735;
  font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 157.143% */
letter-spacing: 0.05px;
}
.divtext1{
  color: #808080;
  font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
letter-spacing: 0.05px;
display: flex;
width: 667px;
justify-self: center;
}
.mt-45{margin-top: 45px;}
.mb-45{margin-bottom: 45px;}
.ml-8{margin-left: 8px;}
.gap-46{gap: 46px;}
.gap-12{gap: 12px;}
.text-center{text-align: center;}
.p-50-0{padding: 0px 60px 0px 60px;}
.br-4{border-radius: 4px;}
.center{
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
}
.w-247{width: 247px !important;}



.newtextbox{
  display: flex;
/* width: 240.64px; */
height: 44px;
padding: 15px 19.8px 15px 20px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 100px;
border: 2px solid rgba(255, 255, 255, 0.60);
background: rgba(247, 247, 247, 0.60);
box-shadow: -81px 146px 47px 0px rgba(0, 0, 0, 0.00), -52px 94px 43px 0px rgba(0, 0, 0, 0.01), -13px 23px 27px 0px rgba(0, 0, 0, 0.03);
color: #393737;
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 14px; /* 100% */
}




body{
  background-color: #F7F7F7;
}

.newboxx{
  /* display: flex; */
width: 492px;
height: 184px;
padding: 35px 32px 59px 32px;
justify-content: center;
align-items: center;
border-radius: 20px;
background: rgba(0, 0, 0, 0.02);
}

.newboxxtext1{
  color: #232323;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}
.newboxxtext2{
  color: #9E9E9E;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}

.bs{
  border-radius: 0px 0px 64px 64px;
background: #F7F7F7;
}

#root{
  background: #F7F7F7;
}

.newfooter{
  background-image: url('https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/9c078d8d-9f8d-4d15-3214-f8c1d8f9a900/xincentive');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mt-80{margin-top: 80px;}

.lastbox{
  border-radius: 32px 0px 0px 32px;
  width: fit-content;
  padding: 35px 60px;
  margin-right: -40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.lastboxt3{
  color: #232323;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 27px; /* 150% */
}
.lastboxt2{
  color: #646464;
  font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.lastboxt4{
  display: flex;
padding: 11px 25px 12.2px 25px;
justify-content: center;
align-items: center;
align-self: stretch;
border-radius: 100px;
background: #232323;
color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 19.2px; /* 120% */
width: fit-content;
margin-top: 20px;
margin-bottom: 55px;
}
.lastboxt1{
  color:  #232323;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: 110.048%; /* 39.617px */
}

.mt-40{
margin-top: 40px;
} .mb-13{
margin-bottom: 13px;
}


.boxlast{
  background-image: url('https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/9c078d8d-9f8d-4d15-3214-f8c1d8f9a900/xincentive');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  background: #232323;

  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -40px;
  
  padding: 35px 60px;
}
.boxlast1{
  color: #FFF;
text-align: right;
font-family: Montserrat;
font-size: 34px;
font-style: normal;
font-weight: 400;
line-height: 40px; /* 117.647% */
letter-spacing: -1.2px;
}

.boxlast2{
  color: #FFF;
text-align: right;
font-family: Montserrat;
font-size: 64px;
font-style: normal;
font-weight: 500;
line-height: 105.6px; /* 165% */
}
.boxlast3{
  color: #FFF;
text-align: right;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 19.2px; /* 120% */
}
.boxlast33{
  color: #FFF;
text-align: right;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 19.2px; /* 120% */
}


.mt-140{
  margin-top: 140px;
}

.w-110{
  width: 110%;
}

.z-index-1{
  z-index: 1;
}











.mb-30{margin-bottom: 30px;}
.mb-60{margin-bottom: 60px;}
.mt-84{margin-top: 84px;} 
.mt-24{margin-top: 24px;} 
.mb-45{margin-bottom: 45px;}
.wp15{
  color: #9B9B9B;
  font-family: Poppins;
  font-size: 19.531px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 153.6% */
  letter-spacing: -0.6px;
  background: #434343;
  padding-top: 21px;
  padding-bottom: 21px;
}
.wp14{
  color: #9E9E9E;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.wp13{
  color: #232323;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.wp12{
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.02);
  padding: 18px 12px;
}
.wp11{
  color: #232323;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 58.333% */
  letter-spacing: -0.6px;
}
.wp10{
  color: #A7A3A3;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px; /* 132.143% */
}
.wp9{
  color: #F9F9F9;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px; /* 285.714% */
  letter-spacing: -0.8px;
}
.wp8{
  color: #F9F9F9;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 108.333% */
  letter-spacing: -0.48px;
}
.wp7{
  color: #9B9B9B;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px; /* 172.222% */
  letter-spacing: -0.6px;
}
.wp6{
  color: #232323;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
  letter-spacing: -1px;
}
.wp5{
  color: #9B9B9B;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.6px;
}
.wp4{
  color: #232323;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 200% */
  letter-spacing: -1.92px;
  margin-top: 110px;
  margin-bottom: 10px;
}
.wp3{
  color: #9B9B9B;
  font-family: Poppins;
  font-size: 19.531px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.6px;
}
.wp2{
  margin-top: 50px;
  margin-bottom: 20px;
  color: #9B9B9B;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 14px; /* 63.636% */
  letter-spacing: -0.6px;
}
.wp1{
  color: #232323;
  font-family: Montserrat;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 77px; /* 137.5% */
  letter-spacing: -2.76px;
}
.wp{
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 1000px;
  margin-top: 170px;
}
.wpp{
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 1000px;
}
.mt-100{margin-top: 100px;}
.mt-120{margin-top: 120px;}
.wwp{background: #232323; padding: 84px 221px}