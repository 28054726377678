/* Base styles */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.menu-toggle {
    display: none; /* Hide toggle button on larger screens */
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    color: #272735;
}

.menu-items {
    display: flex;
    align-items: center;
}
.hide1{display: none;}
/* Mobile view styles */
@media (max-width: 768px) {
    .headernew{
        border-radius: 13.341px;
        background: white;
        // background: rgba(255, 255, 255, 0.51);
        box-shadow: 0px 0px 0px 0.534px rgba(0, 0, 0, 0.02), 0px 0.534px 0.534px 0.267px rgba(0, 0, 0, 0.02), 0px 1.601px 1.601px 0.801px rgba(0, 0, 0, 0.02), 0px 3.203px 3.203px -1.601px rgba(0, 0, 0, 0.02), 0px 6.406px 6.406px -3.203px rgba(0, 0, 0, 0.02), 0px 12.811px 12.811px -6.406px rgba(0, 0, 0, 0.02);
        backdrop-filter: blur(0px);
        display: flex;
        width: 100% !important;
        padding: 16px 32px;
        margin-top: 30px;
        // flex-direction: column;
        // align-items: flex-start;
        gap: 10px;
    }
    .button1p{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .header .menu-items {
        display: flex; 
        flex-direction: row;
        // position: absolute;
        background-color: white;
        top: 60px;
        right: 0;
        width: 100%;
        align-items: flex-start;
        z-index: 1000; /* Ensures menu is above other content */
    }
    .mob-center{justify-items: center;}

    .menu-items{
        // padding: 20px;
    }
    .text1{
        // margin-bottom: 10px;
        border-bottom: 1px solid rgba(214, 211, 209, 0.50);
        // padding-bottom: 15px !important;
    }

    // .mob-mb-15{margin-bottom: 15px !important;justify-self: center !important; margin-top: 50px !important;}

    .hide1{display: block !important;justify-self: center !important;}
    .show{display: block !important;}
    .hide { display: none !important; }


    .header .menu-toggle {
        display: block;
    }

    .header .menu-items.show {
        display: flex; /* This will only apply if 'show' class is added */
    }
}



















.headernew{
    border-radius: 13.341px;
    background: white;
    // background: rgba(255, 255, 255, 0.51);
    box-shadow: 0px 0px 0px 0.534px rgba(0, 0, 0, 0.02), 0px 0.534px 0.534px 0.267px rgba(0, 0, 0, 0.02), 0px 1.601px 1.601px 0.801px rgba(0, 0, 0, 0.02), 0px 3.203px 3.203px -1.601px rgba(0, 0, 0, 0.02), 0px 6.406px 6.406px -3.203px rgba(0, 0, 0, 0.02), 0px 12.811px 12.811px -6.406px rgba(0, 0, 0, 0.02);
    backdrop-filter: blur(0px);
    display: flex;
    width: 905.79px;
    padding: 16px 32px;
    margin-top: 30px;
    // flex-direction: column;
    // align-items: flex-start;
    gap: 10px;
}


.newbtn{
    display: flex;
    padding: 11px 25px 12.2px 25px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 100px;
    background: #6FE17C;
    color: #232323;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px; /* 120% */
}

.newtext{
    color: #232323;
// text-align: center;
// font-family: Poppins;
// font-size: 16px;
// font-style: normal;
// font-weight: 500;
// line-height: 31.663px; /* 197.895% */
// letter-spacing: -0.158px;
}
